import { Theme } from '@material-ui/core/styles';
import { GraySmall } from 'src/theme/colors';

// https://github.com/cssinjs/jss/issues/1344
// use this instead of 'all' when pointerEvents: 'all' is supported
// currently we cannot use pointerEvents: 'all' because MUI
// createStyles sees the type as string when we spread the authScreenStyles
// object into the createStyles function. We cannot wrap the authScreenStyles
// in createStyles because then spreading the object in other places
// does not work
const pointerEventAll = 'all' as const;

export const authScreenStyles = (theme: Theme) => ({
  formContainer: {
    flexGrow: 1,
  },
  fields: {
    display: 'flex',
    flexWrap: 'wrap' as const, // https://github.com/cssinjs/jss/issues/1344
    '& > *': {
      flexGrow: 1,
    },
  },
  navigationActionsContainerDesktop: {
    display: 'flex',
    pointerEvents: pointerEventAll,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navigationActionsContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: pointerEventAll,
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  clientSignUpLinkMobile: {
    display: 'flex',
    pointerEvents: pointerEventAll,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  link: {
    cursor: 'pointer',
    color: GraySmall,
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  optionalLabelHelper: {
    color: GraySmall,
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    height: '48px',
  },
});

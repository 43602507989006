import { Link, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { PortalConfigContext } from 'src/context';
import { getPublicImageURL } from 'src/utils/ImageUtils';

interface StyleProps {
  logoHeight?: number;
}
interface PortalFullLogoProps extends StyleProps {
  isPreviewLogo?: boolean;
  previewFullLogoUrl?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: 0,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    maxHeight: (props: StyleProps) => props.logoHeight,
    width: '100%',
    borderRadius: 4,
    objectFit: 'contain', // this will help in maintaining the aspect ratio of image
  },
}));

const PortalFullLogo = ({
  isPreviewLogo = false,
  previewFullLogoUrl,
  logoHeight = 100,
}: PortalFullLogoProps) => {
  const portalConfig = useContext(PortalConfigContext);
  const classes = useStyles({ logoHeight });

  const logoPath = React.useMemo(() => {
    // for preview logo use the image url passed down in props
    // else use logoImageUrl from portalconfig
    const url = isPreviewLogo
      ? previewFullLogoUrl
      : portalConfig.assets.logo.logoImageUrl;
    if (url)
      return getPublicImageURL(url, {
        resize: {
          height: 100,
        },
      });

    return '';
  }, [isPreviewLogo, previewFullLogoUrl, portalConfig]);

  return (
    <Link href="/" className={classes.root}>
      <img className={clsx(classes.logo)} src={logoPath} alt="logo" />
    </Link>
  );
};

export default PortalFullLogo;
